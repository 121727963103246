import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import styled from 'styled-components';
import { DistinctBenefitsSection } from 'sections/GPT/DistinctBenefitsSection';
import { UniqueCompanySection } from 'sections/GPT/UniqueCompanySection';
import { SolutionsSection } from 'sections/GPT/SolutionsSection';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { DiverseSection } from 'sections/GPT/DiverseSection';
import { TechnologiesSection } from 'sections/GPT/TechnologiesSection';
import { PersonalizedSection } from 'sections/GPT/PersonalizedSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';

export default () => {
  const isMd = useBreakpoint('md');
  const title = 'Supercharge your business growth with GPT innovations';

  return (
    <>
      <SEO
        title="Chat GPT Integrations – Custom GPT Solutions"
        meta={[
          {
            name: 'keywords',
            content: 'GPT integrations, chat gpt integrations, gpt solutions, custom gpt solutions',
          },
        ]}
        description={
          'Discover custom GPT solutions and seamless chat GPT integrations designed to elevate your business processes. Explore our services now!'
        }
      />

      <CommonMargin>
        <StyledSection title={title} variant="secondary" id="GPT" isMobile={isMd} withBreadCrumbs>
          <HeaderSection>
            Transform your business communications with our advanced GPT integrations. From chatbots to mobile apps, our
            AI solutions seamlessly fit into your systems, unlocking new levels of automation and engagement.
          </HeaderSection>
        </StyledSection>

        <DistinctBenefitsSection />
        <UniqueCompanySection />
        <SolutionsSection />
        <DiverseSection />
        <TechnologiesSection />
        <OurOfferSection />
        <PersonalizedSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
